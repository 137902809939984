import { Directive, HostListener, InputSignal, input } from '@angular/core';

import { InsightsEvent, InsightsEventProperties } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

@Directive({
  selector: '[mpAppInsightsEvent]',
  standalone: true,
})
export class AppInsightsEventDirective {
  readonly eventName: InputSignal<InsightsEvent> = input.required<InsightsEvent>({
    alias: 'mpAppInsightsEvent',
  });

  readonly eventAdditionalProperties: InputSignal<InsightsEventProperties | undefined> =
    input<InsightsEventProperties>();

  constructor(private insightsEventsTrackingService: InsightsEventsTrackingService) {}

  @HostListener('click')
  trackedFeatureClick(): void {
    this.insightsEventsTrackingService.trackEvent(this.eventName(), this.eventAdditionalProperties());
  }
}
